import * as styles from './index.module.scss'

import React, { useEffect } from "react"
import { graphql, navigate } from 'gatsby'

import Layout from '../../components/layout/index'
import SectionsRenderer from '../../components/sections/renderer'
import Seo from '../../components/seo'
import { isLoggedIn } from '../../services/auth'
import { pathTo } from '../../routes'

const Page = ({ data: { page } }) => {
  const { title, slug, metaTitle, metaDescription, sharingTitle, sharingDescription, sharingImage, metaKeywords, sections, hasImageHeader, layout, requiresAuthenticatedUser } = page

  useEffect(() => {
    if (requiresAuthenticatedUser && !isLoggedIn()) {
      navigate('/login')
    }
  })

  if (!requiresAuthenticatedUser || isLoggedIn()) {
    return (
      <Layout hasImageHeader={hasImageHeader} layout={layout}>
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDescription={metaDescription?.metaDescription}
          sharingTitle={sharingTitle}
          sharingDescription={sharingDescription?.sharingDescription}
          sharingImage={sharingImage?.file?.url}
          currentUrl={pathTo({ __typename: 'ContentfulPage', slug })}
          keywords={metaKeywords}
        />
        <h1 className={styles.title}>{title}</h1>
        <div>
          <SectionsRenderer sections={sections}/>
        </div>
      </Layout>
    )
  }

  return null
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      title
      slug
      metaTitle
      hasImageHeader
      layout
      metaDescription {
        metaDescription
      }
      sharingTitle
      sharingDescription {
        sharingDescription
      }
      sharingImage {
        title
        description
        contentful_id
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      metaKeywords: keywords
      requiresAuthenticatedUser
      sections {
        __typename
        ... on ContentfulAdmaMembers {
          id
          title
          artistsPerPage
        }
        ... on ContentfulCategory {
          ...Category
        }
        ... on ContentfulJudgesGrid {
          ...JudgesGrid
        }
        ... on ContentfulGateway {
          ...Gateway
        }
        ... on ContentfulGallery {
          ...Gallery
        }
        ... on ContentfulPageHeader {
          ...PageHeader
        }
        ... on ContentfulPostListing {
          ...PostListing
        }
        ... on ContentfulPressdownload {
          ...Pressdownload
        }
        ... on ContentfulQuote {
          ...Quote
        }
        ... on ContentfulText {
          ...Text
        }
        ... on ContentfulYouTubePlayer {
          ...YouTubePlayer
        }
        ... on ContentfulWebsiteHeader {
          ...WebsiteHeader
        }
      }
    }
  }
`
